import {createContext, useContext, useState} from "react";
import {useIntegrator} from "./integrator.context";
import {allPartnersTag} from "../utils/binding.utils";

export enum State {
    Loading = "loading",
    Secret = 'secret',
    Main = 'main',
}

export enum SideState {
    None = 'none',
    User = 'user',
    Story = 'story'
}

export interface NotificationData {
    title: string;
    message: string;
}

interface AppProviderProps {
    children: React.ReactNode;
}

interface AppContextData {
    state: State; setState(state: State): void;
    sideState: SideState; setSideState(state: SideState): void;
    selectedUser: string; setSelectedUser(uid: string): void;
    selectedStory: string; setSelectedStory(uid: string): void;
    secret: string | undefined; setSecret(value: string | undefined): void;
    notification: NotificationData | undefined; setNotification(value: NotificationData | undefined): void;
    showSettings: boolean; setShowSettings(value: boolean): void;
    showAddUser: boolean; setShowAddUser(value: boolean): void;
    showAddTopic: boolean; setShowAddTopic(value: boolean): void;
    showAddComponent: boolean; setShowAddComponent(value: boolean): void;
    showAddUseCase: boolean; setShowUseCase(value: boolean): void;
    showUserComponents: string | undefined; setShowUserComponents(value: string | undefined): void;
    exportAndCheckState(): Promise<boolean>;
}

const AppContext = createContext<AppContextData>({} as AppContextData);

export function AppProvider(props: AppProviderProps) {
    const {exportState} = useIntegrator();
    const [pageState, setState] = useState<State>(State.Loading);
    const [sideState, setSideState] = useState<SideState>(SideState.Story);
    const [selectedUser, setSelectedUser] = useState<string>('');
    const [selectedStory, setSelectedStory] = useState<string>(allPartnersTag);
    const [secret, setSecret] = useState<string | undefined>(undefined);
    const [notification, setNotification] = useState<NotificationData | undefined>(undefined);
    const [showSettings, setShowSettings] = useState<boolean>(false);
    const [showAddUser, setShowAddUser] = useState<boolean>(false);
    const [showAddTopic, setShowAddTopic] = useState<boolean>(false);
    const [showAddComponent, setShowAddComponent] = useState<boolean>(false);
    const [showAddUseCase, setShowUseCase] = useState<boolean>(false);
    const [showUserComponents, setShowUserComponents] = useState<string | undefined>(undefined);

    async function exportAndCheckState(): Promise<boolean> {
        const result = await exportState();
        setNotification({
            title: result ? 'Success' : 'Failed',
            message: result ? 'Successfully uploaded state.' : 'Failed to upload state. You might use the read-only secret.'
        });

        return result;
    }

    return (
        <AppContext.Provider
            value={{
                state: pageState, setState,
                sideState, setSideState,
                selectedUser, setSelectedUser,
                selectedStory, setSelectedStory,
                secret, setSecret,
                notification, setNotification,
                showSettings, setShowSettings,
                showAddUser, setShowAddUser,
                showAddTopic, setShowAddTopic,
                showAddComponent, setShowAddComponent,
                showAddUseCase, setShowUseCase,
                showUserComponents, setShowUserComponents,
                exportAndCheckState
            }}>
            {props.children}
        </AppContext.Provider>
    )
}

export const useApp = () => useContext(AppContext);