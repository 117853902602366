import {ExportResultData, IPlatformClient} from "./IPlatformClient";
import {ComponentData, PublicUserData, StateData} from "../models/platform.model";
import * as HttpClient from './http.client';
import {postFile} from "./http.client";

const ApiUrl = "https://w8dqelklag.execute-api.eu-central-1.amazonaws.com/";

export class PlatformClient implements IPlatformClient {
    async getUser(uid: string, accessToken?: undefined): Promise<PublicUserData> {
        const result = await HttpClient.get(`${ApiUrl}users?uid=${uid}`, accessToken);
        if (!result.success) {
            throw new Error('');
        }

        return result.data as PublicUserData;
    }

    async getStory(uid: string, accessToken?: undefined): Promise<ComponentData> {
        const result = await HttpClient.get(`${ApiUrl}stories?uid=${uid}`, accessToken);
        if (!result.success) {
            throw new Error('');
        }

        return result.data as ComponentData;
    }

    async getState(accessToken?: string): Promise<StateData> {
        const result = await HttpClient.get(`${ApiUrl}state`, accessToken);
        if (!result.success) {
            throw new Error('');
        }

        return result.data as StateData;
    }

    async setState(state: StateData, accessToken?: string): Promise<ExportResultData> {
        const payload = JSON.stringify(state);
        const result = await HttpClient.post(`${ApiUrl}state`, payload, accessToken);
        if (!result.success) {
            throw new Error('');
        }

        return result.data as ExportResultData;
    }

    async uploadFile(id: string, file: File, accessToken?: string): Promise<boolean> {
        const formData = new FormData();
        formData.append("file", file);
        const response = await postFile(`${ApiUrl}assets?id=${id}`, formData, accessToken);
        return response.success;
    }
}