class CredentialsStorageImpl {
    key = 'integration-platform-credentials';

    loadSecret(): string | null {
        return localStorage.getItem(this.key);
    }

    storeSecret(secret: string): void {
        localStorage.setItem(this.key, secret);
    }

    clear(): void {
        localStorage.removeItem(this.key);
    }
}

export const CredentialsStorage = new CredentialsStorageImpl();
