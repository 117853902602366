import Resizer from "react-image-file-resizer";

export function inferMimeType(url: string): string | null {
    const extension = url.split('.').pop()?.toLowerCase();
    switch (extension) {
        case 'mp3':
            return 'audio/mpeg';
        case 'ogg':
            return 'audio/ogg';
        case 'wav':
            return 'audio/wav';
        default:
            return null;
    }
}

export function scaleImage(file: File, scale: number, onImageScaled: (scaledImage: File) => void): void {
    Resizer.imageFileResizer(file, scale, scale, 'png', 50, 0, async output => {
        const newFile = output as File;
        if (!newFile) {
            return;
        }

        onImageScaled(newFile);
    }, 'file');
}