import {CSSProperties} from "react";

export interface SelectableProps<T> {
    value: T;
    options: T[];
    onChange(value: T): void;
    style?: CSSProperties;
}

function Selectable<T extends string | number>(props: SelectableProps<T>) {
    return (
        <select defaultValue={props.value}
                style={{textTransform: 'capitalize'}}
                onClick={e => e.stopPropagation()}
                onChange={(v) => {
                    props.onChange(v.target.value as T);
                }}>
            {props.options.map((id, index) => {
                return <option key={index} value={id}>{id}</option>
            })}
        </select>
    )
}

export default Selectable;