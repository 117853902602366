import Selectable, {SelectableProps} from "./selectable.element";

interface SelectableLabelProps<T> extends SelectableProps<T> {
    name: string;
}

function SelectableLabel<T extends string | number>(props: SelectableLabelProps<T>) {
    return (
        <div className={'layout horizontal center stretch'} style={props.style}>
            <p>{props.name}</p>
            <Selectable value={props.value} options={props.options} onChange={props.onChange}/>
        </div>
    )
}

export default SelectableLabel;