import {Status} from "../../models/platform.model";
import {Box} from "@0xsequence/design-system";

interface ServiceStatusProps {
    status: Status;
    onClick?(): void;
}

const bindings = {
    [Status.Red]: 'red',
    [Status.Yellow]: 'yellow',
    [Status.Green]: 'green'
}

export const ServiceStatus = (props: ServiceStatusProps) => {
    const { status } = props;

    return (
        <Box
            onClick={(e) => {
                e.stopPropagation();
                props.onClick?.();
            }}
            borderRadius="circle"
            width="4"
            height="4"
            minWidth="4"
            display="inline-block"
            className={bindings[status]}
        />
    );
};