export function getRandomNumber(min: number, max: number): number {
    return Math.floor(Math.floor(Math.random() * (max - min + 1)) + min);
}

export function getRandomElement<T>(arr: T[]): T {
    const index = Math.floor(Math.random() * arr.length);
    return arr[index];
}

export function getSeconds(){
    return Math.floor(new Date().getTime() / 1000);
}

export function getRemainingTime(timestampInSeconds: number): string {
    const currentTimeInSeconds = Math.floor(Date.now() / 1000);
    const remainingTimeInSeconds = Math.max(timestampInSeconds - currentTimeInSeconds, 0);
    if (remainingTimeInSeconds <= 0) {
        return 'overdue';
    }

    const secondsInMinute = 60;
    const secondsInHour = 60 * secondsInMinute;
    const secondsInDay = 24 * secondsInHour;

    if (remainingTimeInSeconds >= secondsInDay) {
        const days = Math.floor(remainingTimeInSeconds / secondsInDay);
        return `in ${days}d`;
    } else if (remainingTimeInSeconds >= secondsInHour) {
        const hours = Math.floor(remainingTimeInSeconds / secondsInHour);
        return `in ${hours}h`;
    } else {
        const minutes = Math.floor(remainingTimeInSeconds / secondsInMinute);
        return `in ${minutes}m`;
    }
}
