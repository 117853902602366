import ReactImageUploading from "react-images-uploading";
import {ImageListType} from "react-images-uploading/dist/typings";
import {useState} from "react";
import {scaleImage} from "../../utils/file.utils";
import {useIntegrator} from "../../contexts/integrator.context";

interface ImageProps {
    id: string;
    size?: number;
    upload?: boolean;
    background?: string;
    uploaded?(): void;
}

function Image(props: ImageProps) {
    const {uploadFile} = useIntegrator();
    const [loading, setLoading] = useState<boolean>(false);
    const [images, setImages] = useState<ImageListType>([]);
    const [source, setSource] = useState<string>(`https://sequence-partner-portal-assets.s3.eu-central-1.amazonaws.com/${props.id}.png`);

    async function onChange(value: ImageListType, addUpdateIndex: number[] | undefined) {
        if (loading) {
            return;
        }

        setImages(value as never[]);

        const file = value[0].file;
        if (file === undefined) {
            return;
        }

        scaleImage(file, 256, async newFile => {
            setLoading(true);
            const result = await uploadFile(props.id, newFile);
            setImages([]);
            setLoading(false);

            if (result && props.uploaded) {
                props.uploaded();
            }
        });
    }

    function handleImageError() {
        setSource("./assets/default.png");
    }

    const upload = props.upload ?? true;
    if (upload) {
        return (
            <div onClick={e => e.stopPropagation()}>
                <ReactImageUploading value={images} onChange={onChange} maxNumber={1}>
                    {({
                          onImageUpload,
                          dragProps
                      }) => (
                        <div style={{
                            position: 'relative',
                            borderRadius: 5,
                            width: props.size ?? 50,
                            height: props.size ?? 50,
                            backgroundColor: props.background ?? 'black'}}
                        >
                            <img alt={props.id} style={{
                                borderRadius: 5,
                                width: '100%',
                                height: '100%'}} src={source}
                                 onClick={onImageUpload} {...dragProps}
                                onError={handleImageError}
                            />
                            {loading && <div className={'layout center'} style={{position: 'absolute', width: '100%', height: '100%', top: 0, left: 0, color: 'white', backgroundColor: 'rgba(0,0,0,0.7)', borderRadius: 5}}>
                                <span className={'fa-solid fa-loader'}/>
                            </div>}
                        </div>)
                    }
                </ReactImageUploading>
            </div>
        )
    } else {
        return (
            <div style={{
                position: 'relative',
                borderRadius: 5,
                width: props.size ?? 50,
                height: props.size ?? 50,
                backgroundColor: props.background ?? 'black'
            }}
            >
                <img alt={props.id} style={{
                    borderRadius: 5,
                    width: '100%',
                    height: '100%'}}
                     src={source}
                     onError={handleImageError}
                />
            </div>
        )
    }
}

export default Image;