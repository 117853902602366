import {Box, Text} from "@0xsequence/design-system";
import {useApp} from "../../contexts/appContext";

function NotificationView() {
    const {notification, setNotification} = useApp();

    return (
        <Box style={{position: 'absolute', top: 0, left: 0, width: '100vw', height: '100vh', backgroundColor: 'rgba(0,0,0,0.8)', zIndex: 2}} alignItems={'center'} justifyContent={'center'} onClick={() => setNotification(undefined)}>
            <Box onClick={e => e.stopPropagation()} flexDirection={'column'} alignItems={'center'} gap={'2'} background={'backgroundBackdrop'} borderRadius={'lg'} padding={'4'}>
                <Text color={'white'}>{notification?.title ?? ''}</Text>
                <Text color={'white'}>{notification?.message ?? ''}</Text>
            </Box>
        </Box>
    )
}

export default NotificationView;