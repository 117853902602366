import {State, useApp} from "../../contexts/appContext";
import {useState} from "react";
import {CredentialsStorage} from "../../networking/credentials.storage";

function SecretView() {
    const {setState} = useApp();
    const [secret, setSecret] = useState<string>('');

    async function enter() {
        CredentialsStorage.storeSecret(secret);
        setState(State.Loading);
    }

    return (
        <div className={'layout vertical center gap'} style={{height: '100vh'}}>
            <div className={'layout vertical stretch'} style={{width: '360px', gap: 30, alignItems: 'flex-start'}}>
                <img alt={'logo'} src={'/logo-sequence.svg'} style={{height: 40}}/>
                <div className={'layout horizontal'} style={{width: '100%', gap: 10}}>
                    <input className={'flex-grow'} type={'password'} onChange={v => setSecret(v.target.value)}
                           placeholder={'Enter password...'}/>
                    <button onClick={enter}>
                        <span className={'fa-solid fa-sign-in'}/>
                    </button>
                </div>
            </div>
        </div>
    )
}

export default SecretView;