import {useIntegrator} from "../../contexts/integrator.context";
import {useApp} from "../../contexts/appContext";
import {useEffect, useState} from "react";
import {ServiceStatus} from "../elements/status.element";
import {ChangeConstantsCommand} from "../../commands/changeState.command";
import {getNextStatus} from "../../utils/binding.utils";
import {ComponentData} from "../../models/platform.model";
import InputField from "../elements/InputField";

interface ComponentViewProps {
    edit: boolean;
}

function UseCaseInspector(props: ComponentViewProps) {
    const {state, executeCommand} = useIntegrator();
    const {selectedStory} = useApp();
    const [name, setName] = useState<string>('');
    const [notes, setNotes] = useState<string>('');
    const [guide, setGuide] = useState<string>('');
    const [newLink, setNewLink] = useState<string>('');

    const component = findUseCase();

    useEffect(() => {
        setName(component.name);
        setNotes(component.internalDescription);
        setGuide(component.externalDescription);
    }, [component, selectedStory]);

    function findUseCase(): ComponentData {
        return state.components.find(c => c.uid === selectedStory) ?? {} as ComponentData;
    }

    return (
        <div className={'box layout vertical flex-1'} style={{gap: '1rem', height: '100%'}}>
            <div className={'layout horizontal stretch center'}>
                <div className={'layout horizontal gap-10 center flex-grow'}>
                    <ServiceStatus status={component.status} onClick={async () => {
                        if (!props.edit) {
                            return;
                        }

                        executeCommand(new ChangeConstantsCommand(state => {
                            const index = state.components.findIndex(c => c.uid === component.uid);
                            state.components[index].status = getNextStatus(component.status);
                        }));
                    }}/>
                    <InputField className={'text header flex-grow'} text={name} placeholder={'Enter name...'} onChange={setName} onDone={() => {
                        executeCommand(new ChangeConstantsCommand(state => {
                            const index = state.components.findIndex(c => c.uid === component.uid);
                            state.components[index].name = name;
                        }));
                    }}/>
                </div>
            </div>
            <p>Internal Notes</p>
            <InputField text={notes} placeholder={'Enter...'} area={true}
                        onChange={v => setNotes(v)}
                        onDone={() => {
                            executeCommand(new ChangeConstantsCommand(state => {
                                const index = state.components.findIndex(c => c.uid === component.uid);
                                state.components[index].internalDescription = guide;
                            }));
                        }}
                        style={{height: 100, padding: '5px 10px'}}
            />
            <p>External Guide</p>
            <InputField text={guide} placeholder={'Enter...'} area={true}
                        onChange={v => setGuide(v)}
                        onDone={() => {
                            executeCommand(new ChangeConstantsCommand(state => {
                                const index = state.components.findIndex(c => c.uid === component.uid);
                                state.components[index].externalDescription = guide;
                            }));
                        }}
                        style={{height: 100, padding: '5px 10px'}}
            />
            <p>Links</p>
            <div className={'layout vertical gap flex-grow'} style={{overflowY: 'auto', height: 0}}>
                {component.links && Object.keys(component.links).map((key, index) => {
                    const value = component.links[key];
                    return <LinkPair uid={component.uid} title={key} value={value}/>
                })}
            </div>
            <div className={'layout vertical gap'}>
                <input placeholder={'Enter key...'} value={newLink} onChange={e => {
                    setNewLink(e.target.value);
                }}/>
                <div className={'layout horizontal gap'}>
                    <button className={'flex-grow'} onClick={() => {
                        executeCommand(new ChangeConstantsCommand(state => {
                            const index = state.components.findIndex(c => c.uid === component.uid);
                            state.components[index].links[newLink] = '';
                        }));
                    }}>
                        Add Link
                    </button>
                    <button className={'btn-delete'} onClick={() => {
                        executeCommand(new ChangeConstantsCommand(state => {
                            const index = state.components.findIndex(c => c.uid === component.uid);
                            state.components.splice(index, 1);

                            for (let i = 0; i < state.users.length; i++) {
                                for (let j = 0; j < state.users[i].components.length; j++) {
                                    const index = state.users[i].components.findIndex(c => c.uid === component.uid);
                                    if (index >= 0) {
                                        state.users[i].components.splice(index, 1);
                                    }
                                }
                            }
                        }));
                    }}>
                        <span className={'fa-solid fa-trash'}/>
                    </button>
                </div>
            </div>
        </div>
    )
}

function LinkPair(props: { uid: string, title: string, value: string }) {
    const {executeCommand} = useIntegrator();
    const [link, setLink] = useState<string>(props.value)

    return (
        <div className={'layout horizontal gap'}>
            <p className={'tag'} style={{cursor: 'pointer'}} onClick={() => {
                window.open(props.value);
            }}>
                {props.title}
            </p>
            <InputField className={'flex-grow'} text={props.value} placeholder={'Enter link...'} onChange={v => setLink(v)} onDone={() => {
                executeCommand(new ChangeConstantsCommand(state => {
                    const index = state.components.findIndex(c => c.uid === props.uid);
                    state.components[index].links[props.title] = link;
                }));
            }}/>
        </div>
    )
}

export default UseCaseInspector;