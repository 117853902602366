export enum Status {
    Red = 0,
    Yellow = 1,
    Green = 2
}

export enum PartnerTier {
    Tier1 = "T1",
    Tier2 = "T2",
    Tier3 = "T3",
}

export enum ComponentBindingType {
    Ask = "ask",
    Must = "must"
}

export interface StateData {
    frame: number;
    users: UserData[];
    components: ComponentData[];
    issues: IssueData[];
}

export interface PublicUserData {
    uid: string;
    createdAt: number;
    name: string;
    components: ComponentData[];
}

export interface UserData {
    uid: string;
    createdAt: number;
    name: string;
    description: string;
    tier: PartnerTier;
    components: UserComponentData[];
}

export interface UserComponentData {
    uid: string;
    timeline: number;
    type: ComponentBindingType;
}

export interface ComponentData {
    uid: string;
    createdAt: number;
    updatedAt: number;
    name: string;
    status: Status;
    internalDescription: string;
    externalDescription: string;
    links: {[key: string]: string};
    issues: string[];
}

export interface IssueData {
    uid: string;
    title: string;
    description: string;
    timeline: number;
}
