import {useIntegrator} from "../../contexts/integrator.context";
import {DropTargetMonitor, useDrop} from "react-dnd";
import {ComponentBindingType, PartnerTier, UserComponentData, UserData} from "../../models/platform.model";
import {SideState, useApp} from "../../contexts/appContext";
import {allPartnersTag, determineUserStatus} from "../../utils/binding.utils";
import {getRemainingTime, getSeconds} from "../../utils/math.utils";
import StatusExplainer from "./statusExplainer";
import Image from "./Image";
import {ChangeConstantsCommand} from "../../commands/changeState.command";

interface UserProps {
    user: UserData;
}

export const User = (props: UserProps) => {
    const {state, executeCommand} = useIntegrator();
    const {selectedUser, selectedStory, setSideState, setSelectedUser} = useApp();

    const selected = selectedUser === props.user.uid;
    const userComponent = props.user.components.find((c) => c.uid === selectedStory) ?? {} as UserComponentData;
    const selectedTimeline = props.user.components.length === 0 ? 0 : userComponent.timeline ?? 0;

    function checkCanDrop(monitor: DropTargetMonitor<any, void>): boolean {
        if (!monitor.isOver({shallow: true})) {
            return false;
        }

        const uid = monitor.getItem().id;
        return !props.user.components.find(c => c.uid === uid);
    }

    const [{canDrop}, drop] = useDrop({
        accept: 'component',
        drop: (item: any, monitor) => {
            if (!checkCanDrop(monitor)) {
                return;
            }

            const uid = monitor.getItem().id;
            executeCommand(new ChangeConstantsCommand(state => {
                const useCaseIndex = state.components.findIndex(c => c.uid === uid);
                const userIndex = state.users.findIndex(u => u.uid === props.user.uid);
                if (useCaseIndex < 0 || userIndex < 0) {
                    return;
                }

                state.users[userIndex].components.push({
                    uid: uid,
                    type: ComponentBindingType.Ask,
                    timeline: getSeconds() + 604800
                });
            }))
        },
        collect: (monitor) => {
            return {
                canDrop: checkCanDrop(monitor)
            };
        },
    });

    function getBorder(canDrop: boolean, selected: boolean): string {
        if (canDrop) {
            return 'solid 1px var(--tint-color)';
        } else if (selected) {
            return 'solid 1px rgba(255, 255, 255, 0.3)'
        } else {
            return 'solid 1px transparent'
        }
    }

    return (
        <div ref={drop} className={`label flex-no-shrink`}
             style={{border: getBorder(canDrop, selected)}}
             onClick={() => {
            setSelectedUser(props.user.uid);
            setSideState(SideState.User);
        }}>
            <div className={`layout horizontal center gap-10 flex-grow`} style={{justifyContent: 'flex-start'}}>
                <Image id={props.user.uid} size={36}/>
                <StatusExplainer status={determineUserStatus(props.user, state.components)}/>
                <div className={'tag'}>
                    <p>{props.user.tier ?? PartnerTier.Tier1}</p>
                </div>
                {selectedStory !== allPartnersTag && <>
                    <div className={'tag'}>
                        <p>{`${userComponent.type}`}</p>
                    </div>
                    <div className={'tag'}>
                        <p>{`${getRemainingTime(selectedTimeline)}`}</p>
                    </div>
                </>}
                <p className={'text'} style={{fontWeight: 400}}>
                    {`${props.user.name}`}
                </p>
            </div>
        </div>
    )
}