import {createContext, useContext, useState} from "react";
import {StateData} from "../models/platform.model";
import {CommandBase} from "../commands/commandBase";
import {PlatformClient} from "../networking/platformClient";
import {IPlatformClient} from "../networking/IPlatformClient";
import {CredentialsStorage} from "../networking/credentials.storage";

interface IntegratorProviderProps {
    children: React.ReactNode;
}

interface IntegratorContextData {
    state: StateData;
    forceSetState(state: StateData): void;
    refreshState(): Promise<void>;
    validateSecret(): Promise<boolean>;
    exportState(): Promise<boolean>;
    uploadFile(id: string, file: File): Promise<boolean>;
    executeCommand(command: CommandBase): void;
    goBack(): void;
    goForward(): void;
    canGoBack(): boolean;
    canGoForward(): boolean;
}

interface HistoryData {
    frame: number;
    data: string;
    command: CommandBase | null;
}

const IntegratorContext = createContext<IntegratorContextData>({} as IntegratorContextData);

export function IntegratorProvider(props: IntegratorProviderProps) {
    const [frame, setFrame] = useState<number>(0);
    const [state, setState] = useState<StateData>({} as StateData);
    const [history, setHistory] = useState<HistoryData[]>([]);
    const [historyIndex, setHistoryIndex] = useState<number>(0);

    const client: IPlatformClient = new PlatformClient();

    async function executeCommand(command: CommandBase): Promise<void> {
        const result = command.execute(state);
        if (!result) {
            return;
        }

        const newFrame = frame + 1;
        setFrame(newFrame);

        if (historyIndex > 0) {
            setHistoryIndex(0);
            history.splice(0, historyIndex);
        }

        history.unshift({
            frame: newFrame,
            data: JSON.stringify(state),
            command: command
        });

        setHistory(history);
        forceSetState(state);

        await exportState();
    }

    function goBack() {
        if (!canGoBack()) {
            return;
        }

        setFromHistory(historyIndex + 1);
    }

    function goForward() {
        if (!canGoForward()) {
            return;
        }

        setFromHistory(historyIndex - 1);
    }

    function canGoBack(): boolean {
        return history.length - historyIndex - 1 > 0;
    }

    function canGoForward(): boolean {
        return historyIndex > 0;
    }

    function setFromHistory(index: number) {
        const historyData = history[index];
        setFrame(historyData.frame);
        setHistoryIndex(index);
        setState(JSON.parse(historyData.data));
    }

    async function refreshState(): Promise<void> {
        const state = await client.getState(CredentialsStorage.loadSecret() ?? undefined);

        setState(state);
        setFrame(0);
        setHistoryIndex(0);
        setHistory([{
            frame: 0,
            data: JSON.stringify(state),
            command: null
        }]);
    }

    async function validateSecret(): Promise<boolean> {
        try {
            await client.getState(CredentialsStorage.loadSecret() ?? undefined);
            return true;
        } catch (e) {
            return false;
        }
    }

    async function exportState(): Promise<boolean> {
        if (history.length === 0) {
            return false;
        }

        state.frame++;
        const result = await client.setState(state, CredentialsStorage.loadSecret() ?? undefined);
        if (result.applied) {
            setHistory([]);
        }

        return result.applied;
    }

    async function uploadFile(id: string, file: File): Promise<boolean> {
        const formData = new FormData();
        formData.append("file", file);
        return await client.uploadFile(id, file, CredentialsStorage.loadSecret() ?? undefined);
    }

    function forceSetState(state: StateData) {
        setState(() => ({
            ...state
        }));
    }

    return (
        <IntegratorContext.Provider
            value={{
                state, forceSetState,
                refreshState,
                validateSecret,
                exportState,
                uploadFile,
                executeCommand,
                goBack,
                goForward,
                canGoBack,
                canGoForward,
            }}>
            {props.children}
        </IntegratorContext.Provider>
    )
}

export const useIntegrator = () => useContext(IntegratorContext);