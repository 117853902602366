import {ComponentBindingType, ComponentData} from "../../models/platform.model";
import {useIntegrator} from "../../contexts/integrator.context";
import {SideState, useApp} from "../../contexts/appContext";
import Selectable from "./selectable.element";
import {ChangeConstantsCommand} from "../../commands/changeState.command";
import DatePicker from "react-datepicker";
import StatusExplainer from "./statusExplainer";

interface ComponentProps {
    component: ComponentData;
    userUid?: string;
    timeline?: number;
    type?: string;
    onClick?(): void;
}

export const Component = (props: ComponentProps) => {
    const {executeCommand} = useIntegrator();
    const {setSideState, setSelectedUser, setSelectedStory} = useApp();

    return (
        <div className={'label layout horizontal center stretch'} onClick={() => {
            if (props.onClick) {
                props.onClick?.();
            } else {
                setSelectedUser('');
                setSelectedStory(props.component.uid);
                setSideState(SideState.Story);
            }
        }}>
            <div className={'layout horizontal center gap-10 flex-grow'}>
                <StatusExplainer status={props.component.status}/>
                {props.type && <Selectable value={props.type} options={Object.values(ComponentBindingType)} onChange={type => {
                    executeCommand(new ChangeConstantsCommand(state => {
                        if (props.userUid === undefined) {
                            return;
                        }

                        const userIndex = state.users.findIndex(u => u.uid === props.userUid);
                        if (userIndex < 0) {
                            return;
                        }

                        const cIndex = state.users[userIndex].components.findIndex(c => c.uid === props.component.uid);
                        if (cIndex < 0) {
                            return;
                        }

                        state.users[userIndex].components[cIndex].type = type as ComponentBindingType;
                    }));
                }}
                />}
                {props.timeline && <div onClick={e => e.stopPropagation()}>
                    <DatePicker className={'date-picker'} dateFormat={'MM/dd/YY'} selected={new Date(props.timeline * 1000)} onChange={(date) => {
                        const userUid = props.userUid;
                        const componentUid = props.component.uid;
                        if (date === null || userUid === undefined) {
                            return;
                        }

                        executeCommand(new ChangeConstantsCommand(state => {
                            const uIndex = state.users.findIndex(u => u.uid === userUid);
                            const cIndex = state.users[uIndex].components.findIndex(c => c.uid === componentUid);
                            state.users[uIndex].components[cIndex].timeline = Math.floor(date.getTime() / 1000);
                        }))
                    }}/>
                </div>}
                <p className={'text flex-grow'}>{props.component.name}</p>
                <button style={{padding: '10px 15px'}} onClick={e => {
                    e.stopPropagation();
                    executeCommand(new ChangeConstantsCommand(state => {
                        const uIndex = state.users.findIndex(u => u.uid === props.userUid);
                        const cIndex = state.users[uIndex].components.findIndex(c => c.uid === props.component.uid);
                        if (uIndex < 0 || cIndex < 0) {
                            return;
                        }

                        state.users[uIndex].components.splice(cIndex, 1);
                    }))
                }}>
                    <span className={'fa-solid fa-remove'}/>
                </button>
            </div>
        </div>
    )
}