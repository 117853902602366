import {ComponentData, PublicUserData, Status, UserComponentData, UserData} from "../models/platform.model";

export const allPartnersTag = 'ALL_PARTNERS';

export function getNextStatus(status: Status): Status {
    switch (status) {
        case Status.Red:
            return Status.Yellow;
        case Status.Yellow:
            return Status.Green;
        default:
            return Status.Red;
    }
}

export function determinePublicUserStatus(user: PublicUserData): Status {
    let status = Status.Green;
    for (const component of user.components) {
        if (component.status.valueOf() < status.valueOf()) {
            status = component.status;
        }
    }

    return status;
}

export function determineUserStatus(user: UserData, allComponents: ComponentData[]): Status {
    let status = Status.Green;
    for (const userComponent of user.components) {
        const component = allComponents.find(c => c.uid === userComponent.uid);
        if (component === undefined) {
            continue;
        }

        if (component.status.valueOf() < status.valueOf()) {
            status = component.status;
        }
    }

    return status;
}

export function collectComponents(uids: UserComponentData[], allComponents: ComponentData[]): ComponentData[] {
    const components: ComponentData[] = [];
    for (const uid of uids) {
        const existing = allComponents.find(ac => ac.uid === uid.uid);
        if (existing) {
            components.push(existing);
        }
    }

    return components;
}
