import {CommandBase} from "./commandBase";
import {StateData} from "../models/platform.model";

export class ChangeConstantsCommand extends CommandBase {
    change: (data: StateData) => void;

    constructor(change: (data: StateData) => void) {
        super();
        this.change = change;
    }

    execute(data: StateData): boolean {
        this.change(data);
        return true;
    }
}