import {State, useApp} from "../../contexts/appContext";
import {useIntegrator} from "../../contexts/integrator.context";
import {useEffect} from "react";
import {CredentialsStorage} from "../../networking/credentials.storage";
import {Spinner} from "@0xsequence/design-system";

function LoadingView() {
    const {refreshState} = useIntegrator();
    const {setState} = useApp();

    useEffect(() => {
        loadState();
    }, [loadState]);

    // eslint-disable-next-line react-hooks/exhaustive-deps
    async function loadState() {
        if (CredentialsStorage.loadSecret() === null) {
            setState(State.Secret);
            return;
        }

        await refreshState();
        setState(State.Main);
    }

    return (
        <div className={'layout vertical center gap'} style={{height: '100vh'}}>
            <Spinner/>
        </div>
    )
}

export default LoadingView;