import './styles/index.css';
import "@0xsequence/design-system/styles.css";
import "react-datepicker/dist/react-datepicker.css";
import './styles/App.css';
import './fa/styles/all.css';

import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import {BrowserRouter, Route, Routes} from "react-router-dom";
import UserView from "./components/views/userView";

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

root.render(
  <React.StrictMode>
      <BrowserRouter>
          <Routes>
              <Route path="/" element={<App />} />
              <Route path="/partner/*" element={<UserView />} />
              <Route path="*" element={<App />} />
          </Routes>
      </BrowserRouter>
  </React.StrictMode>
);
