import {IntegratorProvider} from "./contexts/integrator.context";
import {AppProvider, State, useApp} from "./contexts/appContext";
import LoadingView from "./components/views/loading.view";
import SecretView from "./components/views/secret.view";
import NotificationView from "./components/views/notification.view";
import OverviewView from "./components/views/overview.view";
import {CredentialsStorage} from "./networking/credentials.storage";

function App() {
  return (
      <IntegratorProvider>
          <AppProvider>
              <MainP/>
          </AppProvider>
      </IntegratorProvider>
  );
}

function MainP() {
    const {state, notification} = useApp();

    return (
        <div>
            {state === State.Loading && <LoadingView/>}
            {state === State.Secret && <SecretView/>}
            {state === State.Main && <TabPage/>}
            {notification && <NotificationView/>}
        </div>
    )
}

function TabPage() {
    const {setShowSettings, setState} = useApp();

    function logout() {
        CredentialsStorage.clear();
        setShowSettings(false);
        setState(State.Loading);
    }

    return (
        <div className={'layout vertical gap'} style={{height: '100vh'}}>
            <div className={'layout horizontal flex-grow'} style={{padding: '2rem', gap: '1rem'}}>
                <div className={'layout vertical flex-grow'} style={{gap: '2rem'}}>
                    <div className={'layout horizontal stretch center'}>
                        <img alt={'logo'} src={'/logo-sequence.svg'} style={{height: 40}}/>
                        <div className={'layout horizontal'} style={{gap: '1rem'}}>
                            <div className={'layout horizontal gap'}>
                                <button onClick={logout}>
                                    <span className={'fa-solid fa-sign-out'}/>
                                </button>
                            </div>
                        </div>
                    </div>
                    <OverviewView/>
                </div>
            </div>
        </div>
    )
}

export default App;
