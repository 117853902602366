import {useEffect, useState} from "react";
import {ComponentData, PublicUserData} from "../../models/platform.model";
import {useLocation} from "react-router-dom";
import {PlatformClient} from "../../networking/platformClient";
import StatusExplainer from "../elements/statusExplainer";
import {Spinner} from "@0xsequence/design-system";
import {ServiceStatus} from "../elements/status.element";
import {determinePublicUserStatus} from "../../utils/binding.utils";

function UserView() {
    const [user, setUser] = useState<PublicUserData | null>(null);
    const [notFound, setNotFound] = useState<boolean>(false);

    const location = useLocation();
    const uid = location.pathname.substring(9);

    useEffect(() => {
        refreshUser();
    }, [refreshUser]);

    // eslint-disable-next-line react-hooks/exhaustive-deps
    async function refreshUser() {
        try {
            const user = await new PlatformClient().getUser(uid);
            setUser(user);
        } catch (e) {
            setNotFound(true);
        }
    }

    if (notFound) {
        return (
            <div className={'layout vertical center gap'} style={{height: '100vh'}}>
                <p className={'text header'}>{`User ${uid} not found.`}</p>
            </div>
        )
    } else if (user !== null) {
        return (
            <div className={'layout vertical gap'} style={{height: '100vh'}}>
                <div className={'layout vertical flex-grow'} style={{padding: 'var(--padding-large)', gap: 'var(--padding-large)'}}>
                    <div className={'layout vertical flex-grow'} style={{gap: 'var(--padding-large)', alignItems: 'flex-start'}}>
                        <img alt={'logo'} src={'/logo-sequence.svg'} style={{height: 40}}/>
                        <div className={'layout vertical center flex-grow'} style={{width: '100%'}}>
                            <div className={'layout vertical flex-grow'}
                                 style={{width: '1200px', maxWidth: '100%', alignItems: 'flex-start', gap: '2rem'}}>
                                <UserHeader user={user}/>
                                <div className={'layout flex-grow'}
                                     style={{width: '100%'}}>
                                    <StoryList components={user.components}/>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    } else {
        return (
            <div className={'layout vertical center gap'} style={{height: '100vh'}}>
                <Spinner/>
            </div>
        )
    }
}

function UserHeader(props: { user: PublicUserData }) {
    return (
        <div className={'layout horizontal center gap-10'}>
            <ServiceStatus status={determinePublicUserStatus(props.user)}/>
            <p className={'text header'} style={{fontSize: 'var(--font-size-title)'}}>{`${props.user.name} Integration Guide`}</p>
        </div>
    )
}

function StoryList(props: { components: ComponentData[] }) {
    return (
        <div className={'layout vertical'} style={{gap: '0.5rem', width: '100%'}}>
            {props.components.map((component, index) => {
                const linkKeys = Object.keys(component.links);
                return <div key={index} className={'box layout vertical flex-no-shrink'}
                            style={{gap: 'var(--padding-medium)'}}>
                    <div className={'layout horizontal stretch gap-10'} style={{alignItems: 'center'}}>
                        <p className={'text regular'}>{component.name}</p>
                    </div>
                    {linkKeys.length > 0 && <div className={'layout horizontal gap'}>
                        <StatusExplainer status={component.status}/>
                        {linkKeys.map((key, index) => {
                            const value = component.links[key];
                            return <div className={'tag layout horizontal gap center'}
                                        onClick={() => window.open(value)}
                                        style={{cursor: 'pointer'}}>
                                <p>{key}</p>
                                <span className={'fa-light fa-arrow-up-right-from-square'}
                                      style={{color: 'white', fontSize: '12px'}}/>
                            </div>
                        })}
                    </div>}
                    {
                        component.externalDescription !== undefined &&
                        component.externalDescription.length > 0 &&
                        <p>
                            {component.externalDescription}
                        </p>
                    }
                </div>
            })}
        </div>
    )
}

export default UserView;