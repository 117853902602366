import {useIntegrator} from "../../contexts/integrator.context";
import {SideState, useApp} from "../../contexts/appContext";
import {allPartnersTag, determineUserStatus} from "../../utils/binding.utils";
import {useEffect, useState} from "react";
import {User} from "../elements/user.element";
import UserInspector from "./user.inspector";
import UseCaseInspector from "./useCase.inspector";
import {ChangeConstantsCommand} from "../../commands/changeState.command";
import {ComponentBindingType, ComponentData, PartnerTier, Status, UserComponentData} from "../../models/platform.model";
import {getSeconds} from "../../utils/math.utils";
import StatusExplainer from "../elements/statusExplainer";
import { uid } from 'uid/single';
import {DndProvider, useDrag} from "react-dnd";
import {HTML5Backend} from "react-dnd-html5-backend";

function OverviewView() {
    const {state, executeCommand} = useIntegrator();
    const {sideState, selectedStory, setSideState, setSelectedUser, setSelectedStory, setShowAddUser, setShowAddComponent} = useApp();
    const [searchUseCases, setSearchUseCases] = useState<string>('');
    const [searchUsers, setSearchUsers] = useState<string>('');

    const sortedComponents = state.components
        .filter(c => searchUseCases.toLowerCase() === '' || c.name.toLowerCase().includes(searchUseCases.toLowerCase()))
        .sort((a, b) => a.status - b.status);

    const selectedUsers = state.users
        .filter(u => selectedStory === allPartnersTag || u.components.find(c => selectedStory.includes(c.uid)) !== undefined)
        .filter(u => searchUsers.toLowerCase() === '' || u.name.toLowerCase().includes(searchUsers.toLowerCase()))
        .sort((a, b) => determineUserStatus(a, state.components) - determineUserStatus(b, state.components));

    useEffect(() => {
        if (state.users === undefined) {
            return;
        }

        const hasUsers = state.users.length === 0;
        setSelectedUser(hasUsers ? '' : state.users[0].uid);
        setSideState(SideState.User);
    }, [setSideState, setSelectedUser, state.users]);

    function addNewUseCase() {
        const id = uid();
        executeCommand(new ChangeConstantsCommand(state => {
            state.components.push({
                uid: id,
                createdAt: 0,
                updatedAt: 0,
                name: 'New Use Case',
                internalDescription: '',
                externalDescription: '',
                status: Status.Red,
                links: {},
                issues: []
            });
        }));

        setShowAddComponent(false);
        setSelectedUser('');
        setSelectedStory(id);
        setSideState(SideState.Story);
    }

    function addNewUser() {
        executeCommand(new ChangeConstantsCommand(state => {
            const components: UserComponentData[] = [];
            if (selectedStory !== '' && selectedStory !== allPartnersTag) {
                components.push({
                    uid: selectedStory,
                    type: ComponentBindingType.Ask,
                    timeline: getSeconds() + 1000000
                })
            }

            const id = uid();
            state.users.push({
                uid: id,
                createdAt: 0,
                name: 'New User',
                description: '',
                tier: PartnerTier.Tier3,
                components: components
            })
        }));

        setShowAddUser(false);
    }

    return (
        <DndProvider backend={HTML5Backend}>
            <div className={'layout vertical gap flex-grow'}>
                <div className={'layout horizontal flex-grow'} style={{gap: '1rem'}}>
                    <div className={'box layout vertical gap flex-1'} style={{gap: '1.5rem'}}>
                        <div className={'layout horizontal stretch center'}>
                            <div className={'layout horizontal center gap'} style={{gap: '0.7rem'}}>
                                <p className={'text header'}>{`Use Cases`}</p>
                                <button style={{padding: '10px 12px', borderRadius: '50px'}} onClick={addNewUseCase}>
                                    <span className={'fa-solid fa-add'}/>
                                </button>
                            </div>
                            <input placeholder={'Search...'} value={searchUseCases}
                                   onChange={e => setSearchUseCases(e.target.value)}/>
                        </div>
                        <div className={'layout vertical flex-grow'}
                             style={{gap: '0.5rem', height: 0, overflowY: "auto"}}>
                            <div
                                className={`label ${selectedStory === allPartnersTag && 'selected'} layout horizontal stretch flex-no-shrink`}
                                onClick={() => {
                                    setSelectedUser(state.users[0].uid);
                                    setSelectedStory(allPartnersTag);
                                    setSideState(SideState.User);
                                }}>
                                <div className={'layout horizontal gap-10'}>
                                    <p className={'text'}>{'All Partners'}</p>
                                </div>
                            </div>
                            {sortedComponents.map((component, index) => {
                                return <UseCase key={index} component={component}/>
                            })}
                        </div>
                    </div>
                    <div className={'box layout vertical flex-1 gap'} style={{gap: '1.5rem'}}>
                        <div className={'layout horizontal stretch center'}>
                            <div className={'layout horizontal center gap'} style={{gap: '0.7rem'}}>
                                <p className={'text header'}>{`Related Partners`}</p>
                                <button style={{padding: '10px 12px', borderRadius: '50px'}} onClick={addNewUser}>
                                    <span className={'fa-solid fa-add'}/>
                                </button>
                            </div>
                            <input placeholder={'Search...'} value={searchUsers}
                                   onChange={e => setSearchUsers(e.target.value)}/>
                        </div>
                        <div className={'layout vertical flex-grow'}
                             style={{height: 0, overflowY: 'auto', gap: '0.5rem'}}>
                            {selectedUsers.map((user, index) => {
                                return <User key={index} user={user}/>
                            })}
                        </div>
                    </div>
                    {sideState === SideState.User && <UserInspector/>}
                    {sideState === SideState.Story && <UseCaseInspector edit={true}/>}
                </div>
            </div>
        </DndProvider>
    )
}

function UseCase(props: {component: ComponentData}) {
    const {selectedStory, setSelectedUser, setSelectedStory, setSideState} = useApp();

    const selected = selectedStory === props.component.uid;

    const [{opacity}, drag] = useDrag({
        type: 'component',
        item: {
            id: props.component.uid,
        },
        collect: (monitor) => {
            const isDragging = monitor.isDragging();
            const opacity = isDragging ? 0.5 : 1;
            return {
                opacity: opacity,
            };
        },
    });

    return (
        <div ref={drag} style={{opacity: opacity}}
            className={`label ${selected && 'selected'} layout horizontal stretch flex-no-shrink`}
            onClick={() => {
                setSelectedUser('');
                setSelectedStory(props.component.uid);
                setSideState(SideState.Story);
            }}>
            <div className={'layout horizontal center gap-10'}>
                <StatusExplainer status={props.component.status}/>
                <p className={'text'}>{props.component.name}</p>
            </div>
        </div>
    )
}

export default OverviewView;