import {useIntegrator} from "../../contexts/integrator.context";
import {SideState, useApp} from "../../contexts/appContext";
import {ComponentData, PartnerTier, UserData} from "../../models/platform.model";
import {ServiceStatus} from "../elements/status.element";
import {allPartnersTag, determineUserStatus} from "../../utils/binding.utils";
import SelectableLabel from "../elements/selectableLabel.element";
import {ChangeConstantsCommand} from "../../commands/changeState.command";
import {Component} from "../elements/useCase.element";
import {useEffect, useState} from "react";
import InputField from "../elements/InputField";

interface UserInspectorProps { }

function UserInspector(props: UserInspectorProps) {
    const {state, executeCommand} = useIntegrator();
    const {selectedUser, selectedStory, setSideState, setSelectedUser} = useApp();
    const [name, setName] = useState<string>('');
    const [description, setDescription] = useState<string>('');

    const user = findUser();

    useEffect(() => {
        setName(user.name);
        setDescription(user.description);
    }, [user, selectedUser]);

    function findUser(): UserData {
        return state.users.find(u => u.uid === selectedUser) ?? {} as UserData;
    }

    function findComponent(uid: string): ComponentData {
        return state.components.find(c => c.uid === uid) ?? {} as ComponentData;
    }

    function openUser() {
        window.open(`https://www.integration-platform.net/partner/${user?.uid}`)
    }

    const selectedUserComponent = user.components.find(c => c.uid === selectedStory);
    const selectedComponent = state.components.find(c => c.uid === selectedStory);
    const otherComponents = user.components.filter(c => c.uid !== selectedStory);

    return (
        <div className={'box layout vertical flex-1'} style={{gap: '1rem', height: '100%'}}>
            <div className={'layout horizontal stretch center'}>
                <div className={'layout horizontal gap-10 center flex-grow'}>
                    <ServiceStatus status={determineUserStatus(user, state.components)}/>
                    <InputField className={'text header flex-grow'} text={name} placeholder={'Enter name...'} onChange={setName} onDone={() => {
                        executeCommand(new ChangeConstantsCommand(state => {
                            const index = state.users.findIndex(c => c.uid === user.uid);
                            state.users[index].name = name;
                        }));
                    }}/>
                </div>
                {selectedStory !== allPartnersTag && <button onClick={() => {
                    setSelectedUser('');
                    setSideState(SideState.Story);
                }}>
                    <span className={'fa-solid fa-close'}/>
                </button>}
            </div>
            <SelectableLabel name={'Tier'} value={user.tier} options={Object.values(PartnerTier)} onChange={v =>
                executeCommand(new ChangeConstantsCommand(state => {
                    const index = state.users.findIndex(u => u.uid === user.uid);
                    state.users[index].tier = v as PartnerTier;
                }))
            }/>
            <p>Description</p>
            <InputField text={description} placeholder={'Enter...'} area={true}
                        onChange={v => setDescription(v)}
                        onDone={() => {
                            executeCommand(new ChangeConstantsCommand(state => {
                                const index = state.users.findIndex(c => c.uid === user.uid);
                                state.users[index].description = description;
                            }));
                        }}
                        style={{height: 100, padding: '5px 10px'}}
            />
            {selectedComponent && selectedUserComponent && <>
                <p className={'text regular'}>Selected Use Case</p>
                <div className={'layout vertical gap'}>
                    <Component component={selectedComponent} userUid={user.uid} type={selectedUserComponent.type}
                               timeline={selectedUserComponent.timeline}/>
                </div>
            </>}
            <p className={'text regular'}>More Use Cases</p>
            <div className={'layout vertical gap flex-grow'} style={{height: 0, overflowY: 'auto'}}>
                <div className={'layout vertical gap flex-grow'}>
                    {otherComponents.map((userComponent, index) => {
                        const component = findComponent(userComponent.uid);
                        return <Component key={index} component={component} userUid={user.uid}
                                          type={userComponent.type}
                                          timeline={userComponent.timeline}/>
                    })}
                </div>
            </div>
            <div className={'layout horizontal stretch gap'}>
                <button className={'flex-grow'} onClick={openUser}>Open Partner Page</button>
                <button className={'btn-delete flex-grow'} onClick={() => {
                    executeCommand(new ChangeConstantsCommand(state => {
                        const index = state.users.findIndex(c => c.uid === user.uid);
                        state.users.splice(index, 1);
                    }));
                }}>
                    Delete Partner
                </button>
            </div>
        </div>
    )
}

export default UserInspector;