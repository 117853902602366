import {CSSProperties, useRef} from "react";

interface InputFieldProps {
    text: string;
    placeholder: string;
    area?: boolean;
    style?: CSSProperties | undefined;
    className?: string;
    onChange(value: string): void;
    onDone?(): void;
}

function InputField(props: InputFieldProps) {
    const timeout = useRef<NodeJS.Timeout>();

    const handleTyping = () => {
        clearTimeout(timeout.current);

        timeout.current = setTimeout(() => {
            props.onDone?.();
        }, 1000);
    };

    if (props.area) {
        return (
            <textarea className={props.className} style={props.style} onKeyUp={handleTyping} value={props.text} placeholder={props.placeholder}
                   onChange={e => props.onChange(e.target.value)}/>
        );
    } else {
        return (
            <input type="text" className={props.className} style={props.style} onKeyUp={handleTyping} value={props.text} placeholder={props.placeholder}
                   onChange={e => props.onChange(e.target.value)}/>
        );
    }
}

export default InputField;