import React from "react";
import { ServiceStatus } from "./status.element";
import {Status} from "../../models/platform.model";

const bindings = {
    [Status.Red]: 'Blocked',
    [Status.Yellow]: 'In Progress',
    [Status.Green]: 'Ready',
}

function StatusExplainer(props: {status: Status}) {
    return (
        <div className={'tag status-explainer layout horizontal center'} style={{overflow: 'inherit'}}>
            <ServiceStatus status={props.status}/>
            <p>{bindings[props.status]}</p>
        </div>
    )
}

export default StatusExplainer;
